<template>
	<div class="wrap">
		<div class="bg_dark">
			<!-- 고정해더 -->
			<div class="header">
				<div class="top">
					<h2 class="page_tit">{{  $language.common.external_nft }}</h2>
					<div class="util">
						<button class="close">
							<i class="icon icon-close"></i>
						</button>
					</div>
				</div>
			</div>
			<!-- //고정해더 -->
			<!-- 컨텐츠 내용 -->
			<div class="flex_wrap">
				<div class="pt-50 intro_content">
					<div class="intro_top character3">
						<div class="text_guide pt-20">
							<h4>{{  $language.common.Not_exist_nft_wallet }}</h4>
						</div>
						<div class="text_guide_desc pt-5">
							<p>{{  $language.common.nft_wallet_require }}</p>
						</div>
						<div class="btn_center pt-30">
							<button class="btn_center btn_l btn_fill_blue">{{  $language.nft.create_wallet }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia0201'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.external_nft
					, title: this.$language.common.external_nft
					, not_header: true
					, not_footer: true
				}
			}
		}
		, methods: {
			getNftOutside: async function(){
				try{
					const result = this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_nft_outside_list
						, data: {

						}
						, type: true
					})

					if(result.success){
						this.items_nft = result.data
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>